import type { COVERPICTUREWIDTH } from '@confluence/custom-sites-extensions';

import type { CoverPictureStateContainer } from './CoverPictureStateContainer';
import type { UnsplashPhoto } from './EditorCoverPicture/unsplashAPI';

export type FloatingToolbarButtonStyleType = {
	height: string;
	alignItems: 'center';
};

export enum CoverPictureIdPropertyKey {
	PUBLISHED = 'cover-picture-id-published',
	DRAFT = 'cover-picture-id-draft',
}

export type CoverPictureIdContentProperty = {
	key: CoverPictureIdPropertyKey;
	value: string;
	version?: {
		number: number;
	};
};

type GraphqlCoverPictureIdContentProperty = {
	nodes: CoverPictureIdContentProperty[];
};

export type GraphqlQueryDraftCoverPictureId = {
	coverPictureIdDraft: GraphqlCoverPictureIdContentProperty;
};

export type GraphqlQueryContentPublishedCoverPictureId = {
	coverPictureIdPublished: GraphqlCoverPictureIdContentProperty;
};

export type CoverPictureId = {
	value: {
		id: string;
		position: number;
	};
	version: number;
};

export type EditorPageCoverPictureEntryComponentProps = {
	contentId: string;
	isTitleHovered: boolean;
	isPublishingContent: boolean;
	userOffline: boolean;
	contentType: string;
	coverPictureState: CoverPictureStateContainer;
	isUnpublishedDraft: boolean;
	floatingToolbarButtonStyle?: FloatingToolbarButtonStyleType;
	isFixedWidthImageOption?: boolean;
	width?: number;
	leftOffset?: number;
	dynamicCoverImageHeight?: number;
	isReadOnly?: boolean;
	hasCoverPicture: boolean;
	aiSuggestedUnsplashImages?: UnsplashPhoto[];
};

export type EditorPageCoverPictureComponentProps = {
	contentId: string;
	isTitleHovered?: boolean;
	isPublishingContent: boolean;
	contentType: string;
	setIsModalOpen?: (isModalOpen: boolean) => void;
	userOffline: boolean;
	isModalOpen?: boolean;
	hasCoverPicture: boolean;
	coverPictureState: CoverPictureStateContainer;
	isUnpublishedDraft: boolean;
	floatingToolbarButtonStyle?: FloatingToolbarButtonStyleType;
	width?: number;
	dynamicCoverImageHeight?: number;
	isReadOnly?: boolean;
	spaceKey?: string;
	isFixedWidthImageOption?: boolean;
	aiSuggestedUnsplashImages?: UnsplashPhoto[];
};

export type ImagePickerComponentProps = {
	hasCoverPicture: boolean;
	isTitleHovered?: boolean;
	openMediaBrowser: () => void;
	userOffline?: boolean;
	isUploadingPicture: boolean;
	isRegeneratingImage: boolean;
	onRegenerate: () => void;
	onRemoveImage: () => void;
	onAcceptAIUnsplashImage: () => void;
	onImageWidthChange?: (coverPictureWidth: COVERPICTUREWIDTH) => void;
	handleUnsplashUpload: (photoURL: string) => void;
	setIsUploadingPicture: (isUploadingPicture: boolean) => void;
	setIsModalOpen?: (isModalOpen: boolean) => void;
	isModalOpen?: boolean;
	initialPicturePosition: number | undefined;
	onSaveReposition: (position: number) => void;
	shouldShowReposition: boolean;
	setShouldShowReposition: (shouldShowReposition: boolean) => void;
	coverPictureState: CoverPictureStateContainer;
	floatingToolbarButtonStyle?: FloatingToolbarButtonStyleType;
	forceUpdate?: (number: number) => void;
	contentId: string;
	isExternalShare: boolean;
	dynamicCoverImageHeight?: number;
	spaceKey?: string;
	shouldShowAIUnsplashHeaderToolbar: boolean;
};

export type AddHeaderImageButtonProps = {
	userOffline?: boolean;
	isTitleHovered?: boolean;
	openModal: () => void;
	closeModal: () => void;
	openMediaBrowser: () => void;
	isModalOpenState: boolean;
	handleUnsplashUpload: (photoURL: string) => void;
	setIsUploadingPicture: (isUploadingPicture: boolean) => void;
	initialUnsplashInfoArray: Array<object>;
	setInitialUnsplashInfoArray: (unsplashArray: Array<object>) => void;
	coverPictureState: CoverPictureStateContainer;
	floatingToolbarButtonStyle?: FloatingToolbarButtonStyleType;
	contentId: string;
	isExternalShare: boolean;
};

export type HoverOverlayAndDropDownItemsComponentProps = {
	userOffline?: boolean;
	closeModal: () => void;
	openMediaBrowser: () => void;
	setIsRepositionActive: (isRepositionActive: boolean) => void;
	onRemoveImage: () => void;
	onImageWidthChange?: (coverPictureWidth: COVERPICTUREWIDTH) => void;
	handleUnsplashUpload: (photoURL: string) => void;
	setIsUploadingPicture: (isUploadingPicture: boolean) => void;
	initialUnsplashInfoArray: Array<object>;
	setInitialUnsplashInfoArray: (unsplashArray: Array<object>) => void;
	coverPictureState: CoverPictureStateContainer;
	contentId: string;
	isExternalShare: boolean;
	dynamicCoverImageHeight?: number;
	spaceKey?: string;
};

export type RepositionImageComponentProps = {
	initialPicturePosition: number | undefined;
	onSaveReposition: (position: number) => void;
	setIsRepositionActive: (isRepositionActive: boolean) => void;
	setShouldShowReposition: (shouldShowReposition: boolean) => void;
	coverPictureState: CoverPictureStateContainer;
	forceUpdate?: (number: number) => void;
	userOffline?: boolean;
	dynamicCoverImageHeight?: number;
};

export type RendererPageCoverPictureComponentProps = {
	contentId?: any;
	isPagePreview: boolean;
	isSpaceOverview?: boolean;
	coverPicture: {
		draft: CoverPictureId | null;
		published: CoverPictureId | null;
	};
	contentAppearance: { draft: string; published: string };
	hasEmoji?: boolean;
	leftRightPaddingValue?: number;
	isPresenterMode?: boolean;
	coverPictureWidthOverride?: COVERPICTUREWIDTH;
};

export type PreviewPageCoverPictureComponentProps = {
	contentId?: string;
	contentAppearance: string;
	leftRightPaddingValue?: number;
};
