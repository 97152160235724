import { LoadableAfterPaint } from '@confluence/loadable';
import { isConfluenceRedirectToLoomSDKExperimentEnabled } from '@confluence/growth-experiment-helpers';
export { useLoomInsertUrlState } from './useLoomInsertUrlState';

export const PageHeaderLoomEntryPoint = LoadableAfterPaint({
	loader: async () => {
		const { isVariantCohort } = isConfluenceRedirectToLoomSDKExperimentEnabled(); // evaluate experiment here but does NOT fire exposure event
		return isVariantCohort
			? (
					await import(
						/* webpackChunkName: "loadable-PageHeaderLoomEntryPoint" */ './Experiment_RedirectToSdkAfterCrossflow/PageHeaderLoomEntryPoint'
					)
				).PageHeaderLoomEntryPoint
			: (
					await import(
						/* webpackChunkName: "loadable-PageHeaderLoomEntryPoint" */ './PageHeaderLoomEntryPoint'
					)
				).PageHeaderLoomEntryPoint;
	},
});
