import React, { useEffect } from 'react';

import { Box, xcss } from '@atlaskit/primitives';

import { useObjectSidebar } from '@confluence/object-sidebar-api';

import { useWidthResizeObserver } from './useWidthResizeObserver';

const panelPushStyles = xcss({
	display: 'block',
	flex: '1',
	maxWidth: '320px',
});

export const ObjectSidebarPushPlaceholder = () => {
	const [, { setPanelWidth }] = useObjectSidebar();
	const [containerRef, width] = useWidthResizeObserver();

	useEffect(() => {
		setPanelWidth(width);
	}, [width, setPanelWidth]);

	return <Box ref={containerRef} xcss={panelPushStyles} />;
};
