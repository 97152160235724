import type { ReactNode } from 'react';
import React, { useContext, useEffect } from 'react';
import { Query } from 'react-apollo';

import {
	EXTENSION_POINT_HOME_FOOTER_EXPERIENCE,
	EXTENSION_POINT_HOME_HEADER_EXPERIENCE,
	EXTENSION_POINT_EDITOR_SAVEBAR_EXPERIENCE,
	ExperienceSuccess,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { usePageContentId, usePageSpaceKey } from '@confluence/page-context';
import { getSingleParam, RoutesContext } from '@confluence/route-manager';
import { ATL_GENERAL, ATL_FOOTER, ATL_EDITOR_SAVEBAR } from '@confluence/web-item-location';

import type {
	WebPanelLocationQuery as WebPanelLocationQueryType,
	WebPanelLocationQueryVariables as WebPanelLocationQueryVariablesType,
} from './__types__/WebPanelLocationQuery';
import { WebPanel } from './WebPanel';
import { WebPanelLocationQuery } from './WebPanelLocationQuery.graphql';

export type WebPanel = {
	completeKey: string;
	html: string;
	weight: number;
	label: string;
};

type WebPanelLocationProps = {
	location: string;
	style?: { [key: string]: string };
	// Please note it is default to "cache-and-network".
	// You can safely put it to "cache-first" only when the data don't change during the entire browser session.
	fetchPolicy?: 'cache-first' | 'cache-and-network' | 'network-only';
	onCompleted?: () => void;
	children?: (props: { panels: WebPanel[] }) => ReactNode;
	renderWhenLoading?: boolean;
};

export const WebPanelLocation = ({
	location,
	style = {},
	renderWhenLoading,
	fetchPolicy = 'cache-and-network',
	children,
	onCompleted,
}: WebPanelLocationProps) => {
	const [spaceKey] = usePageSpaceKey();
	const [contentId] = usePageContentId();
	const { getQueryParams } = useContext(RoutesContext);
	const queryParams = getQueryParams();
	const experienceTracker = useContext(ExperienceTrackerContext);

	const EXPERIENCE_NAME_BY_LOCATION = {
		[ATL_FOOTER]: EXTENSION_POINT_HOME_FOOTER_EXPERIENCE,
		[ATL_GENERAL]: EXTENSION_POINT_HOME_HEADER_EXPERIENCE,
		[ATL_EDITOR_SAVEBAR]: EXTENSION_POINT_EDITOR_SAVEBAR_EXPERIENCE,
	};

	const experienceName = EXPERIENCE_NAME_BY_LOCATION[location] || null;

	useEffect(() => {
		if (experienceName) {
			experienceTracker.start({ name: experienceName });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const spaceIdentifier =
		spaceKey || getSingleParam(queryParams, 'spaceKey') || getSingleParam(queryParams, 'key');
	const contentIdentifier = contentId || getSingleParam(queryParams, 'pageId');

	// TODO: The task to remove this hack is captured in CFE-1873.
	// There's some weird scenario where the UI ends up with a space key of "byDraft" relating to the way tinymce
	// creates content. Given that it's tinymce specific, I'm applying this hack for now and we can remove it once we're
	// at 100% rollout for the Fabric editor.
	if (spaceIdentifier === 'byDraft') return null;

	const variables = contentIdentifier
		? { contentId: contentIdentifier, location }
		: { spaceKey: spaceIdentifier, location };

	return (
		<Query<WebPanelLocationQueryType, WebPanelLocationQueryVariablesType>
			query={WebPanelLocationQuery}
			variables={variables}
			fetchPolicy={fetchPolicy}
			onCompleted={onCompleted}
		>
			{({ data, loading, error }) => {
				// For transitions, loading and data is true
				if (loading && data) {
					return null;
				}

				if (!renderWhenLoading) {
					if (loading || error || !data) {
						return null;
					}
				}

				const panels = (data?.webPanels || []).filter((panel) => panel) as WebPanel[];

				// For panels that don't have a placeholder
				if (!renderWhenLoading && !panels.length) {
					if (experienceName) {
						experienceTracker.succeed({
							name: experienceName,
						});
					}
					return null;
				}

				return (
					<>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
						<div style={style}>
							{children
								? children({ panels })
								: panels.map((panel) => <WebPanel key={panel.completeKey} html={panel.html} />)}
						</div>
						{experienceName ? <ExperienceSuccess name={experienceName} /> : null}
					</>
				);
			}}
		</Query>
	);
};
